import React from 'react'

const Like = props => (
  <svg
    aria-label="Like"
    viewBox="0 0 48 48"
    height="48pt"
    width="48pt"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M23.99291,15.87187l1.0159-0.8889c1.3968-1.2275,3.118-1.8341,4.8535-1.8341 c2.0316,0,4.0351,0.8324,5.4883,2.4549c2.5961,2.9347,2.4127,7.3508-0.3386,10.0597l-9.8284,8.7357 c-0.6794,0.6026-1.7019,0.6026-2.3814,0l-9.8143-8.7357c-2.7512-2.6948-2.9346-7.125-0.3386-10.0597 c1.4532-1.6225,3.4567-2.4549,5.4884-2.4549c1.7213,0,3.4567,0.6066,4.8535,1.8341L23.99291,15.87187"
    />
  </svg>
)

export default Like
