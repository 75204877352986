import React from 'react'

const Retweet = props => (
  <svg
    aria-label="Retweet"
    height="512pt"
    viewBox="0 -91 512 512"
    width="512pt"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="m512 250.644531-79.796875 79.671875-80.363281-80.03125 28.210937-28.328125 31.871094 31.738281v-193.613281c.035156-5.277343-1.996094-10.246093-5.71875-13.992187-3.757813-3.785156-8.777344-5.867188-14.128906-5.867188h-215.230469l-40.144531-39.980468h255.375c16.082031 0 31.171875 6.277343 42.492187 17.667968 11.265625 11.34375 17.417969 26.382813 17.332032 42.363282v193.882812l31.851562-31.804688zm-184.039062 39.449219h-208.035157c-5.355469 0-10.371093-2.082031-14.128906-5.863281-3.722656-3.746094-5.753906-8.71875-5.71875-13.996094v-193.613281l31.871094 31.738281 28.210937-28.328125-80.363281-80.03125-79.796875 79.675781 28.25 28.289063 31.851562-31.800782v193.878907c-.085937 15.980469 6.066407 31.023437 17.332032 42.363281 11.320312 11.390625 26.410156 17.667969 42.492187 17.667969h255.375l-40.144531-39.980469zm0 0"
    />
  </svg>
)

export default Retweet
